import React, { Component, Suspense } from 'react';
import axios from 'axios';
import { uniq } from 'lodash';
import Modal from '@mui/material/Modal';
import { connect } from 'react-redux';
import withMySnackbar from 'hooks/withMySnackbar';
import DialogContent from '@mui/material/DialogContent';

import templateEmail from './mailTemplate';
import SendMail from '../../components/Mail/SendMail';
import Spinner from '../../components/UI/Spinner/Spinner';

const ModalAttachement = React.lazy(() => import('../../components/Mail/ModalAttachement'));
class Mailbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attribut: this.props.attribut,
            type: this.props.type,
            objectMail: this.props.objectMail,
            destinataire: [],
            Attachment: this.props.Attachment,
            changeState: false,
        };
    }

    componentDidMount() {
        if (this.props.sendto === "client") {
            axios.defaults.withCredentials = true;
            axios.get('/legacy/contact?client=' + this.props.infoIntervention.client).then(response => {
                this.setState({ ...this.state, destinataire: response.data.contact.map(item => (item.mails)).flat() });
            });
        } else {
            let newDestinataire = [];
            this.props.destinataire.forEach(item => {
                if (item.intervenant_id !== -1) {
                    newDestinataire.push(item.intervenant.mail);
                }
            });
            newDestinataire = uniq(newDestinataire.flat());
            this.setState({ ...this.state, destinataire: newDestinataire });
        }
    }

    OpenModal = () => {
        this.setState({ changeState: true });
    };

    closeModal = () => {
        this.setState({ changeState: false });
    };

    selectedelem = (selected) => {
        var newState = [...this.state.Attachment];
        selected.map((index) => {
            newState.push(index);
        });
        this.setState({ Attachment: newState });
        this.closeModal();
    };

    sendMail = (destinataire, objectMail, bodyMail, idFile, nameFile, cc) => {
        const header = {
            IDMastore: this.props.infoIntervention.id_mastore,
            id_attachment: this.state.Attachment[0].id,
            Destinataires: destinataire,
            ObjetMail: objectMail,
            Cc: cc,
            BodyMail: bodyMail,
            Attachment: idFile,
            NameAttachment: nameFile,
            Attribut: this.props.attribut,
        };
        axios.defaults.withCredentials = true;
        if (this.props.sendto === "intervenant") {
            axios.post('/legacy/sendMailIntervenant', header)
                .then(response => {
                    this.props.modalClosed();
                    this.props.snackbar.enqueueSnackbar("Mail envoyé", { variant: 'success' });
                }).catch(response => {
                    this.props.snackbar.enqueueSnackbar("Erreur lors de l'envoi", { variant: 'error' });
                });
        } if (this.props.sendto === "client") {
            axios.post('/legacy/sendMailClient', header)
                .then(response => {
                    this.props.modalClosed();
                    this.props.snackbar.enqueueSnackbar("Mail envoyé", { variant: 'success' });
                }).catch(response => {
                    this.props.snackbar.enqueueSnackbar("Erreur lors de l'envoi", { variant: 'error' });
                });
        }
    };

    removeelem = (i) => {
        if (i > 0) {
            let newState = [...this.state.Attachment];
            newState.splice(i, 1);
            this.setState({ Attachment: newState });
        }
    };

    render() {
        return (
            <React.Fragment>
                <SendMail
                    user={this.props.user}
                    sendMail={this.sendMail}
                    OpenModal={this.OpenModal}
                    RemoveElem={this.removeelem}
                    attribut={this.props.attribut}
                    client={this.state.destinataire}
                    objetMail={this.state.objectMail}
                    Attachment={this.state.Attachment}
                    modalClosed={this.props.modalClosed}
                    Body={() => templateEmail(this.state.attribut, this.props.infoIntervention, this.state.objectMail)}
                    id_mastore={this.props.infoIntervention.id_mastore}
                />
                <Modal
                    onClose={this.closeModal}
                    open={this.state.changeState}
                >
                    <DialogContent>
                        <Suspense fallback={<Spinner />}>
                            <ModalAttachement
                                closeModal={this.closeModal}
                                attribut={this.state.attribut}
                                selectedelem={this.selectedelem}
                                IDMastore={this.props.infoIntervention.id_mastore}
                            />
                        </Suspense>
                    </DialogContent>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: {
            mail: state.mail,
            lastname: state.lastname,
            firstname: state.firstname,
            telephone: state.telephone,
        }
    };
};

export default connect(mapStateToProps, null)(withMySnackbar(Mailbox));

